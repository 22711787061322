import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { selectCurrentAccountId } from "@redux/AuthSlice";
import { type ReportType } from "../../__generated__/GenerateReportButton_GenerateReportMutation.graphql";
import { selectHasPermissions } from "../../redux/CurrentUserSlice";
import { type ValidatedFieldConfig } from "../ui/ValidatedField";

export const ReportTypeSelect = (fieldConfig: ValidatedFieldConfig<ReportType>) => {
	const hasPermissions = useSelector(selectHasPermissions);

	const isHarkins = hasPermissions(["AccountPermission_Auth_Reports"]);
	const currentAccountId = useSelector(selectCurrentAccountId);
	const isRandAccount = currentAccountId === "Account:0377a1e0-45b6-46bf-a245-3457b0ece116";

	const options: Array<{ label: string; value: ReportType }> = [
		{ label: "Grouped by Resource", value: "GroupedByResourceReport" },
		{ label: "Projects", value: "ProjectReport" },
		{ label: "Unstaffed Assignments", value: "UnstaffedReport" },
		{ label: "Grouped by Stages", value: "GroupedByStageReport" },
		{ label: "Gap report", value: "GapReport" },
		{ label: "Availability report", value: "AvailabilityReport" },
		{ label: "Current field staff location", value: "CurrentFieldStaffLocation" },
		{ label: "Availability forecast report", value: "AvailabilityForecast" },
		{ label: "Grouped by Stages w Contact Info", value: "GroupedByStageWithContactInfoReport" },
	];

	if (isHarkins) {
		options.push(
			{
				label: "Grouped by Stages (with next assignment)",
				value: "HarkinsGroupedByStageReport",
			},
			{
				label: "Recently changed assignments",
				value: "HarkinsAssignmentWithSupersReport",
			},
		);
	}
	if (isRandAccount) {
		options.push({ label: "Resource Report", value: "RandAssignmentsReport" });
	}

	return (
		<Dropdown
			name={fieldConfig.fieldName}
			value={fieldConfig.fieldValue}
			options={options}
			onChange={(e) => {
				fieldConfig.updateField(e.value);
			}}
			filter={true}
			placeholder={fieldConfig.placeholder}
		/>
	);
};
