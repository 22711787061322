import { Button } from "@thekeytechnology/framework-react-components";
import { graphql } from "babel-plugin-relay/macro";
import moment from "moment-timezone";
import { OverlayPanel } from "primereact/overlaypanel";
import { Panel } from "primereact/panel";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { SyncWithRandPreconDwhButton } from "@components/sync-with-rand-precon-dwh-button";
import { UpdateAssignmentsFromDynamicsButton } from "@components/update-assignments-from-dynamics-button";
import { ProjectStagesTabs } from "@screens/project-view/parts/project-stages-tab";
import { ProjectViewFiltersPart } from "@screens/project-view/parts/project-view-filters-part";
import { type ProjectsGridPart_QueryFragment$key } from "../../../../__generated__/ProjectsGridPart_QueryFragment.graphql";
import { type ProjectsGridPart_ScenarioFragment$key } from "../../../../__generated__/ProjectsGridPart_ScenarioFragment.graphql";
import { AddProjectToScenarioCard } from "../../../../components/relay/add-project-to-scenario-card/add-project-to-scenario-card.component";
import { ExportAssignmentsButton } from "../../../../components/relay/ExportAssignmentsButton";
import { ImportAssignmentsButton } from "../../../../components/relay/ImportAssignmentsButton";
import { ProjectCard } from "../../../../components/relay/project-card/project-card.component";
import { ExpandProjectsButton } from "../../../../components/ui/ExpandProjectsButton";
import { FilterViewSelector } from "../../../../components/ui/FilterViewSelector";
import { TkButton } from "../../../../components/ui/TkButton";
import { TkSecondaryText } from "../../../../components/ui/TkSecondaryText";
import {
	selectIsProjectsExpanded,
	selectPage,
	selectScenarioProjectFilters,
	setPage,
} from "../../../../redux/ProjectViewSlice";

const QUERY_FRAGMENT = graphql`
	fragment ProjectsGridPart_QueryFragment on Query {
		...projectStagesTabs_ProjectStages
		...FilterViewSelector_QueryFragment @arguments(filterByViewType: ProjectView)
		...projectViewFiltersPart_QueryFragment
		Viewer {
			Auth {
				currentAccount {
					...updateAssignmentsFromDynamicsButton_AccountFragment
				}
			}
		}
	}
`;

const FRAGMENT = graphql`
	fragment ProjectsGridPart_ScenarioFragment on Scenario {
		id
		projects {
			edges {
				node {
					id
					project {
						name
						startDate
						endDate
						stage {
							id
							name
						}

						division {
							id
						}
						region {
							id
						}
					}
					assignments {
						edges {
							node {
								isExecutive
								validAssignmentRoles {
									name
								}
								project {
									id
								}
								person {
									id
									assignmentRole {
										name
										id
									}
								}
							}
						}
					}
					...projectCard_ProjectFragment
				}
			}
		}
		...addProjectToScenarioCard_ScenarioFragment
		...projectCard_ScenarioFragment
		...updateAssignmentsFromDynamicsButton_ScenarioFragment
		...projectViewFiltersPart_ScenarioFragment
		...syncWithRandPreconDwhButton_ScenarioFragment
	}
`;

const PAGE_SIZE = 50;
const ASSIGNMENT_TRESHOLD = 200;

interface OwnProps {
	className?: string;
	scenarioFragmentRef: ProjectsGridPart_ScenarioFragment$key;
	queryFragmentRef: ProjectsGridPart_QueryFragment$key;
}

export const ProjectsGridPart = ({
	className,
	scenarioFragmentRef,
	queryFragmentRef,
}: OwnProps) => {
	const dispatch = useDispatch();
	const projectFilters = useSelector(selectScenarioProjectFilters);
	const query = useFragment<ProjectsGridPart_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef);
	const scenario = useFragment<ProjectsGridPart_ScenarioFragment$key>(
		FRAGMENT,
		scenarioFragmentRef,
	);
	const isExpanded = useSelector(selectIsProjectsExpanded);
	const page = useSelector(selectPage);
	const gridRef = useRef<HTMLDivElement>();
	const [paginationDisabled, setPaginationDisabled] = useState<boolean>(false);
	const ref = useRef<OverlayPanel>(null);

	useEffect(() => {
		gridRef.current?.scrollTo(0, 0);
	}, [page]);

	const projects = (scenario.projects.edges!.map((e) => e!.node!) || [])
		.filter((p) => {
			const assignments = p.assignments.edges!.map((e) => e!.node!);
			const openAssignments = assignments.filter((a) => !a.person).length;

			const passesDivisionFilter =
				!projectFilters.filterByDivisions?.length ||
				projectFilters.filterByDivisions.includes(p.project.division?.id || "");
			const passesRegionFilter =
				!projectFilters.filterByRegions?.length ||
				projectFilters.filterByRegions.includes(p.project.region?.id || "");
			const passesNameFilter =
				!projectFilters.filterByName?.length ||
				p.project.name.toLowerCase().includes(projectFilters.filterByName.toLowerCase());
			const passesStaffingFilter =
				!projectFilters.filterByStaffing ||
				(projectFilters.filterByStaffing === "Fully staffed" && openAssignments === 0) ||
				(projectFilters.filterByStaffing === "Not Fully Staffed" && openAssignments > 0);

			const passesDateFromFilter =
				!projectFilters.filterByDateFrom?.length ||
				moment(p.project.startDate).isAfter(moment(projectFilters.filterByDateFrom));
			const passesDateToFilter =
				!projectFilters.filterByDateTo?.length ||
				moment(p.project.endDate).isBefore(moment(projectFilters.filterByDateTo));

			const passedStageFilter =
				!projectFilters.filterByStage ||
				p.project.stage?.id === projectFilters.filterByStage;

			const passedExecutives =
				!projectFilters.filterByExecutives ||
				assignments.some(
					(a) =>
						a.isExecutive &&
						projectFilters.filterByExecutives?.includes(a.person?.id || ""),
				);

			return (
				passesDivisionFilter &&
				passesRegionFilter &&
				passesNameFilter &&
				passesStaffingFilter &&
				passesDateFromFilter &&
				passesDateToFilter &&
				passedStageFilter &&
				passedExecutives
			);
		})
		.sort((x, y) => {
			switch (projectFilters.sorting) {
				case "ByNameAsc":
					return x.project?.name?.localeCompare(y.project.name);
				case "ByNameDesc":
					return -x.project?.name?.localeCompare(y.project.name);
				case "ByStartDateAsc":
					return x.project?.startDate?.localeCompare(y.project.startDate);
				case "ByStartDateDesc":
					return -x.project?.startDate?.localeCompare(y.project.startDate);
				case "ByEndDateAsc":
					return x.project?.endDate?.localeCompare(y.project.endDate);
				case "ByEndDateDesc":
					return -x.project?.endDate?.localeCompare(y.project.endDate);
			}
			return 0;
		});

	const fromElement = page * PAGE_SIZE;
	const isPaginationNecessary =
		projects.map((x) => x.assignments.edges?.length || 0).reduce((x, y) => x + y, 0) >
			ASSIGNMENT_TRESHOLD &&
		isExpanded &&
		!paginationDisabled;
	const projectsToShow = isPaginationNecessary
		? projects.slice(fromElement, fromElement + PAGE_SIZE)
		: projects;
	const isPaginated = projects.length > projectsToShow.length;

	return (
		<div className={classNames("flex flex-column", className)}>
			<div className="flex w-12 align-items-center hide-print">
				<ProjectStagesTabs projectStagesFragmentRef={query} />

				<div className={"ml-auto mr-2"}>
					<UpdateAssignmentsFromDynamicsButton scenarioFragmentRef={scenario} />
					<SyncWithRandPreconDwhButton scenarioFragmentRef={scenario} />
				</div>
				<div className={"mr-2"}>
					<Button
						content={{ label: "Views", icon: "pi pi-search", iconPosition: "left" }}
						onClick={(e: any) => ref.current?.toggle(e)}
					/>
					<OverlayPanel ref={ref} showCloseIcon>
						<Panel header={"Save / Load Views"}>
							<p className="m-0">
								<FilterViewSelector
									queryFragmentRef={query}
									viewType={"ProjectView"}
								/>
							</p>
						</Panel>
					</OverlayPanel>
				</div>

				<TkButton
					icon="pi pi-print"
					className="mr-2"
					label="Print"
					onClick={() => {
						setPaginationDisabled(true);
						setTimeout(() => {
							window.print();
							setTimeout(() => {
								setPaginationDisabled(false);
							}, 3000);
						}, 1000);
					}}
				/>
				<ImportAssignmentsButton className="mr-2" />
				<ExportAssignmentsButton scenarioId={scenario.id} />
				<ExpandProjectsButton />
			</div>

			<ProjectViewFiltersPart
				className={"mt-2 mb-2"}
				queryRef={query}
				scenarioFragment={scenario}
			/>

			{isPaginated && (
				<div className="flex justify-content-center align-items-center mb-2 mt-5 hide-print">
					<TkButton
						className="mr-4"
						label={"Previous page"}
						disabled={page === 0}
						onClick={() => dispatch(setPage(page - 1))}
					/>
					<TkSecondaryText className="mr-4">
						Showing page {page + 1} of {Math.ceil(projects.length / PAGE_SIZE)} (
						{page * PAGE_SIZE + 1} - {Math.min((page + 1) * PAGE_SIZE, projects.length)}{" "}
						/ {projects.length})
					</TkSecondaryText>
					<TkButton
						label={"Next page"}
						disabled={(page + 1) * PAGE_SIZE >= projects.length}
						onClick={() => dispatch(setPage(page + 1))}
					/>
				</div>
			)}
			<div
				className="flex flex-wrap mt-2 pt-3 overflow-y-scroll to-table-print"
				ref={gridRef as any}
			>
				{projectsToShow.map((p) => {
					return (
						<ProjectCard
							key={p.id}
							scenarioFragmentRef={scenario}
							projectFragmentRef={p}
						/>
					);
				})}
				<AddProjectToScenarioCard scenarioFragmentRef={scenario} />
			</div>
		</div>
	);
};
