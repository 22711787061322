import { Tooltip } from "@thekeytechnology/framework-react-components";
import { classNames } from "primereact/utils";
import React, { useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { ContextMenu } from "@components/context-menu";
import {
	ContextMenuKind,
	type ContextMenuOption,
} from "@components/context-menu/context-menu.types";
import { EditProjectButton } from "@components/relay/edit-project-button";

import { MapsButton } from "@components/relay/project-card/parts/maps-button";
import { RemoveProjectFromScenarioButton } from "@components/relay/project-card/parts/remove-project-from-scenario-button";
import { ProjectDetailsButton } from "@components/relay/project-details-button";
import { PROJECT_FRAGMENT, SCENARIO_FRAGMENT } from "./project-card.graphql";
import { type ProjectCardProps } from "./project-card.interface";
import { type projectCard_ProjectFragment$key } from "../../../__generated__/projectCard_ProjectFragment.graphql";
import { type projectCard_ScenarioFragment$key } from "../../../__generated__/projectCard_ScenarioFragment.graphql";
import {
	selectIsProjectsExpanded,
	selectSelectedProjectId,
	setSelectedProjectId,
} from "../../../redux/ProjectViewSlice";
import { truncateString } from "../../../utils/string-utils";
import { AddressDisplay } from "../../ui/AddressDisplay";
import { CurrencyDisplay } from "../../ui/CurrencyDisplay";
import { TkCard } from "../../ui/TkCard";
import { AssignmentsInProjectList } from "../AssignmentsInProjectList";
import { ProjectDateTimeDisplay } from "../ProjectDateTimeDisplay";

export const ProjectCard = React.memo(
	({ style, scenarioFragmentRef, projectFragmentRef }: ProjectCardProps) => {
		const scenario = useFragment<projectCard_ScenarioFragment$key>(
			SCENARIO_FRAGMENT,
			scenarioFragmentRef,
		);
		const projectInScenario = useFragment<projectCard_ProjectFragment$key>(
			PROJECT_FRAGMENT,
			projectFragmentRef,
		);
		const color = `#${projectInScenario.project.stage?.color ?? "214ce2"} !important`;
		const projectBudget = scenario.budget.projectBudgets.find(
			(p) => p.projectRef === projectInScenario.project.id,
		);

		const openAssignments = projectInScenario.assignments
			.edges!.map((e) => e!.node!)
			.filter((a) => !a.person).length;
		const dispatch = useDispatch();

		const selectedProjectId = useSelector(selectSelectedProjectId);
		const isProjectSelected = selectedProjectId === projectInScenario.project.id;

		const isProjectsExpanded = useSelector(selectIsProjectsExpanded) || isProjectSelected;

		const targetId = useId().replace(":", "").replace(":", "");
		const contextMenuOptions: ContextMenuOption[] = [
			{
				kind: ContextMenuKind.override,
				node: (
					<RemoveProjectFromScenarioButton
						scenarioId={scenario.id}
						projectId={projectInScenario.project.id}
					/>
				),
			},
			{
				kind: ContextMenuKind.override,
				node: (
					<MapsButton
						scenarioId={scenario.id}
						projectInScenarioId={projectInScenario.id}
					/>
				),
			},
			{
				kind: ContextMenuKind.override,
				node: (
					<EditProjectButton
						className="hide-print"
						hideLabel={true}
						projectFragmentRef={projectInScenario.project}
					/>
				),
			},
			{
				kind: ContextMenuKind.override,
				node: (
					<ProjectDetailsButton
						className="hide-print"
						projectFragmentRef={projectInScenario}
						scenarioId={scenario.id}
					/>
				),
			},
		];
		return (
			<>
				<ProjectCardBase
					style={{ ...style, pageBreakInside: "avoid" }}
					key={projectInScenario.id}
					className={classNames(
						{
							selected: isProjectSelected,
						},
						"to-inline-block-print",
					)}
				>
					<HeaderWrapper>
						<Tooltip content={projectInScenario.project.name} target={`#${targetId}`} />
						<Title
							id={targetId}
							className="m-0 cursor-pointer"
							color={color}
							onClick={() => {
								dispatch(
									setSelectedProjectId(
										isProjectSelected
											? undefined
											: projectInScenario.project.id,
									),
								);
							}}
						>
							{truncateString(projectInScenario.project.name, 30)}
						</Title>

						<ButtonsWrapper>
							<ContextMenu options={contextMenuOptions} />
						</ButtonsWrapper>
					</HeaderWrapper>
					<ProjectInfo>
						<IconWrapper>
							<i className="pi pi-calendar" />
						</IconWrapper>
						<ValueWrapper>
							<ProjectDateTimeDisplay
								projectFragmentRef={projectInScenario.project}
							/>
						</ValueWrapper>
					</ProjectInfo>
					<ProjectInfo>
						<IconWrapper>
							<i className="pi pi-map-marker" />
						</IconWrapper>
						<ValueWrapper>
							<AddressDisplay value={projectInScenario.project.address} />
						</ValueWrapper>
					</ProjectInfo>

					{projectBudget &&
						projectBudget.maximumBudget > 0 &&
						projectBudget.budgetedCost > 0 && (
							<ProjectInfo className="mb-1">
								<IconWrapper>
									<i className="pi pi-dollar" />
								</IconWrapper>
								<ValueWrapper
									className={
										projectBudget.budgetedCost <= projectBudget.maximumBudget
											? "text-green-500"
											: "text-orange-500"
									}
								>
									<span className="mr-2">Budgeted:</span>
									<CurrencyDisplay value={projectBudget.budgetedCost} /> /{" "}
									<CurrencyDisplay value={projectBudget.maximumBudget} />
								</ValueWrapper>
							</ProjectInfo>
						)}
					{projectBudget &&
						projectBudget.maximumBudget > 0 &&
						projectBudget.utilizedCost > 0 && (
							<ProjectInfo>
								<IconWrapper>
									<i className="pi pi-dollar opacity-0" />
								</IconWrapper>
								<ValueWrapper
									className={
										projectBudget.utilizedCost <= projectBudget.maximumBudget
											? "text-green-500"
											: "text-orange-500"
									}
								>
									<span className="mr-2">Utilized:</span>
									<CurrencyDisplay value={projectBudget.utilizedCost} /> /{" "}
									<CurrencyDisplay value={projectBudget.maximumBudget} />
								</ValueWrapper>
							</ProjectInfo>
						)}

					{isProjectsExpanded && (
						<AssignmentsInProjectList
							scenarioFragmentRef={scenario}
							projectFragmentRef={projectInScenario}
						/>
					)}

					{openAssignments > 0 && (
						<OpenAssignmentsTag>
							{openAssignments} unfulfilled assignment{openAssignments > 1 && "s"}
						</OpenAssignmentsTag>
					)}
				</ProjectCardBase>
			</>
		);
	},
);

export const ProjectCardBase = styled(TkCard)`
	position: relative;
	width: 300px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	box-shadow: none;
	height: max-content;

	.p-card-content {
		padding: 0;
	}

	&.selected {
		border: 1px solid green;
	}

	h2 {
		color: #214ce2;
		font-size: 1rem;
		font-weight: 600;
	}
`;

const OpenAssignmentsTag = styled.div`
	position: absolute;
	top: -8px;
	right: -8px;
	border: 1px solid #272d44;
	display: flex;
	padding: 4px 10px;
	background: #fff;
	align-items: center;
	border-radius: 2px;
	font-size: 0.7rem;

	@media print {
		top: 0;
		right: 0;
	}
`;

const ProjectInfo = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
`;

const IconWrapper = styled.div`
	margin-right: 10px;
`;
const ValueWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.8rem;
`;

const ButtonsWrapper = styled.div`
	flex-shrink: 0;
`;
const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	justify-content: space-between;
	flex-wrap: nowrap;
`;
const Title = styled.h2<{ color: string }>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${({ color }) => color};
`;
